import React from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';

import { useAlmondApiQuery } from '~/modules/api';
import { ReferralCompact } from '~/modules/referral';
import { usePatientSidebar } from '~/modules/routing';
import { AccordionTitle, Sidebar } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { useDocuments, useListenForNewAttachments } from '../../hooks';
import { PatientInformation } from '../PatientInformation';
import { SharedMedia } from '../SharedMedia';

import { themedStyles } from './styles';

import type { SidebarProps } from '~/modules/ui';

export const PatientSidebar = (props: Pick<SidebarProps, 'backgroundContent'>) => {
  const [styles] = useTheme(themedStyles);
  const currentUser = useCurrentUser();
  const patientUuid = currentUser?.patientUuid as string | null;
  const { isSidebarOpen } = usePatientSidebar();

  useListenForNewAttachments();

  const { data: sharedMediaData } = useDocuments(patientUuid, 'media');
  const { data, error } = useAlmondApiQuery(patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/` : null);

  return (
    <Sidebar isOpen={isSidebarOpen} footer={<ReferralCompact />} backgroundContent={props.backgroundContent}>
      <View style={styles.container}>
        <PatientInformation data={data} error={error} />
      </View>
      {!!sharedMediaData?.length && (
        <>
          <AccordionTitle style={styles.header}>Shared Media</AccordionTitle>
          <SharedMedia />
        </>
      )}
    </Sidebar>
  );
};
