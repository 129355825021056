import React from 'react';

import { IconButton } from '@almond/ui';

type BackButtonProps = {
  onBack: () => void;
};

export const BackButton = (props: BackButtonProps) => {
  return (
    <IconButton source="arrow-back" onPress={props.onBack} color="text" testID="Back" accessibilityLabel="Go back" />
  );
};
