import { ScrollView, useBrowserTypeMap, useTheme } from '@almond/ui';

import { useAdminSidebar } from '~/modules/routing';
import { ArtemisHeaderOptions } from '~/modules/ui';

import { themedStyles } from './styles';

import type { PropsWithChildren } from 'react';

export const TodoPageWrapper = (props: PropsWithChildren) => {
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();
  const { isSidebarOpen, toggleSidebar } = useAdminSidebar();

  return (
    <ArtemisHeaderOptions
      onBack={isMobile && isSidebarOpen ? toggleSidebar : null}
      title={isMobile && isSidebarOpen ? 'More Info' : null}
    >
      <ScrollView
        containerStyle={styles.background}
        style={styles.scrollContainer}
        contentContainerStyle={styles.wrapper}
      >
        {props.children}
      </ScrollView>
    </ArtemisHeaderOptions>
  );
};
