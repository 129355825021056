import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ActivityIndicator, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { ErrorContainer } from '~/modules/logging';
import { Channel, MessageInput, MessageList, MessagingLayout, PatientSidebar, useChannel } from '~/modules/messaging';
import { PatientPageTitle, usePatientSidebar } from '~/modules/routing';
import { ArtemisHeaderOptions } from '~/modules/ui';
import { userIdToConversationId } from '~/modules/user';

import { themedStyles } from './styles';

export { ErrorContainer as ErrorBoundary };

const PatientMessages: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const { client } = useChatContext();
  const { isMobile } = useBrowserTypeMap();
  const conversationId = userIdToConversationId(client.user?.id);
  const { channel } = useChannel(conversationId, true);
  const { isSidebarOpen, toggleSidebar } = usePatientSidebar();

  return (
    <ArtemisHeaderOptions
      onBack={isMobile && isSidebarOpen ? toggleSidebar : null}
      title={(() => {
        if (isSidebarOpen) return 'More Info';
        if (isMobile) return 'You & Care Team';

        return null;
      })()}
    >
      <PatientPageTitle title="Messages" />
      <MessagingLayout isLoading={!channel} sidebar={<PatientSidebar />}>
        <View style={styles.channel}>
          {channel && (
            <View style={[styles.channelWindow, StyleSheet.absoluteFill]}>
              <Channel channel={channel} role="patient">
                <View style={styles.channelContent}>
                  <MessageList addParamsToLabLink />
                  <MessageInput />
                </View>
              </Channel>
            </View>
          )}
          {!channel && <ActivityIndicator />}
        </View>
      </MessagingLayout>
    </ArtemisHeaderOptions>
  );
};

export default PatientMessages;
