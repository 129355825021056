import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  wrapper: {
    // Order reversed so clicking an image brings up the image
    // viewer overlay on top of the sidebar.
    // If we aren't using the default Stream image view overlay,
    // we can switch this to "row" and swap the order of the
    // chat window and sidebar in the DOM
    flexDirection: 'row-reverse',
    flex: 1,
    maxWidth: '100%',
  },
  channelContainer: {
    flex: 1,
    maxWidth: '100%',
  },
  loadingContainer: {
    backgroundColor: theme.lightSecondaryBackground,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hide: {
    display: 'none',
  },
}));
