import React from 'react';
import { View } from 'react-native';

import { Text } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';

import { ArtemisHeaderOptions, useArtemisHeaderOptions } from './ArtemisHeaderOptions';
import { BackButton } from './BackButton';
import { unstable_styles as cssStyles } from './Header.module.css';

export { ArtemisHeaderOptions };

export type HeaderProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  leftAlignTitle?: boolean;
  title?: string;
};

export const Header: React.FC<HeaderProps> = props => {
  const { onBack, title } = useArtemisHeaderOptions();

  const currentPage = props.title ?? title;

  const showCentered = !props.leftAlignTitle || props.left || onBack;

  const titleEle = (
    <Text
      fontStyle="bold"
      size={currentPage ? 'xl' : 'xxl'}
      style={combineUnstableStyles(cssStyles.title, showCentered && cssStyles.centerText)}
    >
      {currentPage || 'almond'}
    </Text>
  );

  const left = showCentered ? props.left : titleEle;

  return (
    <View style={cssStyles.header} testID="ArtemisHeader">
      <View style={cssStyles.left}>{onBack ? <BackButton onBack={onBack} /> : left}</View>
      <View style={cssStyles.center}>{showCentered && titleEle}</View>
      <View style={cssStyles.right}>{props.right}</View>
    </View>
  );
};
