import { Slot, useGlobalSearchParams } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiQuery } from '~/modules/api';
import { ChromeBrowserExtensionCarePlanHandler } from '~/modules/extension';
import { ErrorContainer } from '~/modules/logging';
import { AdminSidebar, MessagingLayout, useChannel } from '~/modules/messaging';
import { CATEGORIES, useGetTodos } from '~/modules/todos';

import type { ElationDetailsParams } from '~/types';

export { ErrorContainer as ErrorBoundary };

export default () => {
  const { channel } = useChatContext();
  const { elationId } = useGlobalSearchParams<ElationDetailsParams>();

  const { data, error } = useAlmondApiQuery(
    elationId ? `/patients/by_elation/${elationId as '{elation_patient_id}'}` : null
  );

  const conversationId = data?.authId ? data.authId.replace('auth0|', '') : undefined;

  useChannel(conversationId, !!conversationId);

  const todos = useGetTodos({ patientUuid: data?.uuid });

  if (error) {
    throw error;
  }

  return (
    <ChromeBrowserExtensionCarePlanHandler categories={CATEGORIES} todos={todos}>
      <MessagingLayout showJoinInThread isLoading={!channel} sidebar={<AdminSidebar />}>
        <Slot />
      </MessagingLayout>
    </ChromeBrowserExtensionCarePlanHandler>
  );
};
