import React from 'react';

import { Slot } from 'expo-router';
import Head from 'expo-router/head';

import { Chat } from '~/modules/messaging';
import { AdminHeader } from '~/modules/routing';

const AdminGroupLayout: React.FC = () => {
  return (
    <Chat>
      <Head>
        <title>Artemis - Almond ObGyn</title>
      </Head>
      <AdminHeader />
      <Slot />
    </Chat>
  );
};

export default AdminGroupLayout;
