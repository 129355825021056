import React from 'react';

import { Text, useBrowserType, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export const Left = () => {
  const [styles] = useTheme(themedStyles);
  const browserType = useBrowserType();

  return (
    <Text fontStyle="bold" size="m" style={styles.logo}>
      {browserType === 'desktop' ? 'internal ' : ''}
      <Text fontStyle="bold" size="m" style={styles.admin}>
        {'dashboard'}
      </Text>
    </Text>
  );
};
